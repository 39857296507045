import {AxiosError} from "axios";
import * as React from "react";
import {Button, Modal, ProgressBar, Tab, Tabs} from "react-bootstrap";
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {ActionBar} from "../../common/ui/actionbar/ActionBar";
import {CombinedCronPicker, Mode} from "../../common/ui/cron/CombinedCronPicker";
import {CronValidationResult} from "../../common/ui/cron/CronUtil";
import {DetailHeader} from "../../common/ui/detailsection/DetailHeader";
import {createCommonMetadataFields, DetailView, FieldInfo, Formats} from "../../common/ui/detailsection/DetailView";
import {SectionHeader} from "../../common/ui/detailsection/SectionHeader";
import {ErrorDisplay} from "../../common/ui/errordisplay/ErrorDisplay";
import {LcdIcon} from "../../common/ui/icon/LcdIcon";
import {MasterDetailLayout} from "../../common/ui/layouts/MasterDetailLayout";
import {Spinner} from "../../common/ui/spinner/Spinner";
import {ValidationResult} from "../../common/ui/validation/ProductValidation";
import {asyncDataSelectors} from "../../common/util/asyncdata/reducerUtil";
import {getParameterFromOwnProps} from "../../common/util/Util";
import {WithApi, WithApiProperties} from "../../common/util/WithApi";
import {JobStartStopButton} from "../JobStartStopButton";
import {DecodeFailureResolution, ImportJob, JobStatus} from "../model";
import {importJobSubmoduleName, selectors as jobSelectors} from "../selectors";
import {actions} from "./actions";
import {ImportJobFilesFilter} from "./ImportJobFilesFilter";
import {JobFilesList} from "./JobFilesList";
import {IgnoreDeletedFilesConfigurator} from "./configuration/IgnoreDeletedFilesConfigurator";
import {ForceRecrawlFilesConfigurator} from "./configuration/ForceRecrawlFilesConfigurator";
import {DecodeFailureResolutionConfigurator} from "./configuration/DecodeFailureResolutionConfigurator";

interface JobDetailPageStateProps {
  job: ImportJob;
  isLoading: boolean;
  isFinished: boolean;
  isError: boolean;
  error: Error;
}

interface ImportJobUpdates {
  jobSchedule: string;
  ignoreDeletedFiles: boolean;
  forceRecrawlFiles: boolean;
  decodeFailureResolution: DecodeFailureResolution;
}

interface JobDetailPageDispatchProps {
  initialLoad: () => Promise<ImportJob>;
  onUpdateSchedule: (job: ImportJob, newSchedule: string) => void;
  onUpdate: (job: ImportJob, updates: ImportJobUpdates) => void;
  clearJobFilesFilter: () => void;
}

type JobDetailPageProps = JobDetailPageStateProps & JobDetailPageDispatchProps & WithApiProperties;

interface JobDetailPageState {
  cronPickerMode: Mode;
  editedCronString: string;
  editedIgnoreDeletedFiles: boolean;
  editedForceRecrawlFiles: boolean;
  editedDecodeFailureResolution: DecodeFailureResolution;
  showConfirmationDialog: boolean;
}

const MESSAGES = defineMessages({
  detailTab: {
    id: "studio.jobs.job-detail-page.details",
    defaultMessage: "Details",
  },
  configurationTab: {
    id: "studio.jobs.job-detail-page.configuration",
    defaultMessage: "Configuration",
  },
});

export class JobDetailPageComponent extends React.Component<JobDetailPageProps & InjectedIntlProps, JobDetailPageState> {

  _previousJobProgress: number;

  constructor(props) {
    super(props);
    const cronString = props.job ? props.job.jobSchedule : undefined;
    const mode = cronString ? (CombinedCronPicker.canUseSimpleMode(cronString) ? "simple" : "advanced") : "notset";
    const ignoreDeleted = props.job ? props.job.ignoreDeletedFiles : true;
    const forceRecrawl = props.job ? props.job.forceRecrawlFiles : false;
    const decodeFailureResolution = props.job ? props.job.decodeFailureResolution : DecodeFailureResolution.DELETE;
    this.state = {
      editedCronString: cronString,
      cronPickerMode: mode,
      editedIgnoreDeletedFiles: ignoreDeleted,
      editedForceRecrawlFiles: forceRecrawl,
      editedDecodeFailureResolution: decodeFailureResolution,
      showConfirmationDialog: false
    };
  }

  UNSAFE_componentWillMount() {
    this.props.clearJobFilesFilter();
    this.props.initialLoad().then((job: ImportJob) => {
      const cronString = job.jobSchedule;
      const mode = cronString ? (CombinedCronPicker.canUseSimpleMode(cronString) ? "simple" : "advanced") : "notset";
      this.setState(Object.assign({}, this.state,
          {
            editedCronString: job.jobSchedule,
            cronPickerMode: mode,
            editedIgnoreDeletedFiles: job.ignoreDeletedFiles,
            editedForceRecrawlFiles: job.forceRecrawlFiles,
            editedDecodeFailureResolution: job.decodeFailureResolution
          }));
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {job: currentJob} = this.props;
    const {job: updatedJob} = nextProps;
    if (updatedJob) {
      const nextProgress = updatedJob.jobProgress;
      if (!this._previousJobProgress) {
        this._previousJobProgress = nextProgress;
      } else {
        if (currentJob && currentJob.jobProgress) {
          this._previousJobProgress = currentJob.jobProgress;
        } else {
          this._previousJobProgress = nextProgress;
        }
      }
    }
  }

  renderActionBarButtons() {
    const {job} = this.props;
    return (<JobStartStopButton job={job} compact={false}/>);
  }

  validateSchedule = (cronStringToValidate: string): Promise<CronValidationResult> => {
    const {api, job} = this.props;
    return api.validateJobSchedule(job.id, cronStringToValidate).then((apiValidationResult: ValidationResult) => {
      const hasWarnings = apiValidationResult.errorMessages.length > 0 ||
                          apiValidationResult.warningMessages.length > 0;
      return {
        valid: apiValidationResult.severity === "OK",
        warning: hasWarnings ? apiValidationResult.errorMessages.join(", ").concat(
            apiValidationResult.warningMessages.join(", ")) : null,
      };
    });
  }

  switchMode = (newMode) => {
    //when switching modes, revert back to the job's schedule
    const {job} = this.props;
    if (newMode === "notset") {
      this.setState({...this.state, editedCronString: undefined, cronPickerMode: newMode});
    } else if (newMode === "simple" &&
               (!CombinedCronPicker.canUseSimpleMode(this.state.editedCronString) || !job.jobSchedule)) {
      this.setState({...this.state, editedCronString: job.jobSchedule || "0 * * * * *", cronPickerMode: newMode});
    } else if (newMode === "advanced" && !this.state.editedCronString) {
      this.setState({...this.state, editedCronString: "0 * * * * *", cronPickerMode: newMode});
    } else {
      this.setState({...this.state, cronPickerMode: newMode});
    }
  }

  render() {
    const {job, isLoading, isFinished, isError, error} = this.props;
    const id = getParameterFromOwnProps(this.props, "id"); //maybe injected by withRouter

    if (isError) {
      if ((error as any).config) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 404) {
          return (
              <div>
                <h2><FormattedMessage id="studio.jobs.job-detail-page.no-job-found"
                                      defaultMessage="Could not find job with id: {id}" values={{id}}/></h2>
                <p><Link to="/jobs"><FormattedMessage id="studio.jobs.job-detail-page.back-to-overview"
                                                      defaultMessage="Go back to jobs overview"/></Link></p>
              </div>
          );
        }
      }
      return <ErrorDisplay error={error}/>;
    }

    if (isLoading) {
      return (
          <div>
            <DetailHeader title={<FormattedMessage id="studio.jobs.job-detail-page.loading-job"
                                                   defaultMessage="Loading job..."/>}/>
            <Spinner/>
          </div>
      );
    }

    if (isFinished && job) {
      const rootPath = job.dataRootPath;
      const hasConfigurationChanged = (job.jobSchedule || null) !== (this.state.editedCronString || null)
                                      || job.ignoreDeletedFiles !== this.state.editedIgnoreDeletedFiles
                                      || job.forceRecrawlFiles !== this.state.editedForceRecrawlFiles
                                      || job.decodeFailureResolution !== this.state.editedDecodeFailureResolution

      // V180-908: Animation of the progress bar has disabled if the progress goes backwards,
      // because this causes that the progress bar can not show the progress properly.
      // If the user starts an already completed job again, the progress first goes to
      // zero and then increase again, and this causes progress visualization problem.
      const isProgressGoingBackwards = job.jobProgress < this._previousJobProgress;

      const updateJob = () => {
        this.props.onUpdate(job, {
          ignoreDeletedFiles: this.state.editedIgnoreDeletedFiles,
          forceRecrawlFiles: this.state.editedForceRecrawlFiles,
          decodeFailureResolution: this.state.editedDecodeFailureResolution,
          jobSchedule: this.state.editedCronString ? this.state.editedCronString : null,
        });
      };

      const applyConfigurationStringChanges = (
          <div>
            <Button onClick={() => {
              if (!this.state.editedIgnoreDeletedFiles && job.ignoreDeletedFiles) {
                this.setState({...this.state, showConfirmationDialog: true});
              } else {
                updateJob();
              }
            }}>
              <LcdIcon icon="ok"/><FormattedMessage
                id="studio.jobs.job-detail-page.apply-changes"
                defaultMessage="Apply changes"/>
            </Button>
            &nbsp;
            <Button onClick={() => {
              this.setState(Object.assign({}, this.state,
                  {editedCronString: job.jobSchedule, editedIgnoreDeletedFiles: job.ignoreDeletedFiles}), () => {
                const cronString = job.jobSchedule;
                const mode = cronString ? (CombinedCronPicker.canUseSimpleMode(cronString) ? "simple" : "advanced")
                                        : "notset";
                this.switchMode(mode);
              });
            }}><LcdIcon icon="delete"/><FormattedMessage
                id="studio.jobs.job-detail-page.discard-changes"
                defaultMessage="Discard changes"/>
            </Button>
          </div>
      );

      const closeDialog = () => this.setState({...this.state, showConfirmationDialog: false});

      const masterPane = (
          <div>
            <Modal show={this.state.showConfirmationDialog} onHide={closeDialog} backdrop="static">
              <Modal.Header closeButton={true}>
                <Modal.Title><FormattedMessage id="studio.jobs.job-detail-page.confirmation-title"
                                               defaultMessage="Apply changes to crawl job"/></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <b><FormattedMessage id="studio.jobs.job-detail-page.warning" defaultMessage="Warning:"/></b>
                  <div>
                    <FormattedMessage id="studio.jobs.job-detail-page.deletion-warning-1"
                                      defaultMessage="Enabling deleted files detection will remove Data and Styles from LuciadFusion if the corresponding files are unavailable when this job runs."/><br/>
                    <FormattedMessage id="studio.jobs.job-detail-page.deletion-warning-2"
                                      defaultMessage="The Data and Styles will also be removed from any products they belong to."/>
                  </div>
                  <br/>
                  <div>
                    <FormattedMessage id="studio.jobs.job-detail-page.confirmation-body"
                                      defaultMessage="Are you sure you want to apply these changes?"/>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div>
                  <Button onClick={() => {
                    updateJob();
                    this.setState({...this.state, showConfirmationDialog: false});
                  }}>
                    <LcdIcon icon="ok"/>
                    {/* No need to define a default message for this key because it's already defined above, we just want to use same message here.*/}
                    <FormattedMessage id="studio.jobs.job-detail-page.apply-changes" defaultMessage="Apply changes"/>
                  </Button>
                  <Button onClick={closeDialog}><FormattedMessage id="studio.jobs.job-detail-page.cancel"
                                                                  defaultMessage="Cancel"/></Button>
                </div>
              </Modal.Footer>
            </Modal>
            <div className="files-header">
              <ImportJobFilesFilter/>
              <div>
                <JobFilesList/>
              </div>
            </div>
          </div>

      );
      const detailPane = (
          <div>
            <DetailHeader title={<FormattedMessage id="studio.jobs.job-detail-page.job-header"
                                                   defaultMessage="Crawl Job ({rootPath})"
                                                   values={{rootPath}}/>}/>
            <ActionBar>
              {this.renderActionBarButtons()}
            </ActionBar>
            <SectionHeader> <FormattedMessage id="studio.jobs.job-detail-page.progress"
                                              defaultMessage="Progress"/></SectionHeader>
            <ProgressBar className={isProgressGoingBackwards ? "disableProgressBarAnimation" : ""}
                         bsStyle="success"
                         active={job.jobState === JobStatus.RUNNING}
                         now={job.jobProgress ? job.jobProgress * 100.0 : 0}
                         label={(job.jobProgress ? (100.0 * job.jobProgress).toFixed(2) : 0) + "%"}/>
            <br/>

            <Tabs defaultActiveKey="Details" bsStyle="pills" justified={false} animation={true} className={"tabs"}>
              <Tab eventKey="Details" title={this.props.intl.formatMessage(MESSAGES.detailTab)}>
                <DetailView fields={([
                  {
                    key: "Data root",
                    name: <FormattedMessage id="studio.jobs.job-detail-page.data-root" defaultMessage="Data root"/>,
                    value: job.dataRootPath,
                  },
                  {
                    key: "Status",
                    name: <FormattedMessage id="studio.jobs.job-detail-page.status" defaultMessage="Status"/>,
                    value: job.jobState.toString(),
                  },
                  {
                    key: "Last execution time",
                    name: <FormattedMessage id="studio.jobs.job-detail-page.last-execution-time"
                                            defaultMessage="Last execution time"/>,
                    value: job.lastExecutionTime,
                    format: Formats.DATETIME,
                  },
                  {
                    key: "Last execution status",
                    name: <FormattedMessage id="studio.jobs.job-detail-page.last-execution-status"
                                            defaultMessage="Last execution status"/>,
                    value: job.lastExecutionResult,
                  },
                  // Somehow the app renders this page using the summary job object that doesn't have crawlStats before
                  // rendering it again with the detail job loaded from /import-job/{id}, so to avoid exceptions we must
                  // account for crawlStats being undefined. The user should never see this page rendered without crawlStats.
                  {
                    key: "Files crawled",
                    name: <FormattedMessage id="studio.jobs.job-detail-page.files-crawled"
                                            defaultMessage="Files crawled"/>,
                    value: job.crawlStats && job.crawlStats.crawledFilesCount,
                  },
                  {
                    key: "Files skipped",
                    name: <FormattedMessage id="studio.jobs.job-detail-page.files-skipped"
                                            defaultMessage="Files skipped"/>,
                    value: job.crawlStats && job.crawlStats.skippedFilesCount,
                  },
                  {
                    key: "Files failed",
                    name: <FormattedMessage id="studio.jobs.job-detail-page.files-failed"
                                            defaultMessage="Files failed"/>,
                    value: job.crawlStats && job.crawlStats.failedFilesCount,
                  },
                ] as FieldInfo[]).concat(createCommonMetadataFields(job, this.props.intl))}/>
              </Tab>
              <Tab eventKey="Configuration" title={this.props.intl.formatMessage(MESSAGES.configurationTab)}>
                <IgnoreDeletedFilesConfigurator ignoreDeletedFiles={this.state.editedIgnoreDeletedFiles}
                                                handleIgnoreDeletedFilesChange={(ignoreDeletedFiles) => this.setState(
                                                    {editedIgnoreDeletedFiles: ignoreDeletedFiles})}/>
                <ForceRecrawlFilesConfigurator forceRecrawlFiles={this.state.editedForceRecrawlFiles}
                                               handleForceRecrawlFilesChange={(forceRecrawlFiles) => this.setState(
                                                   {editedForceRecrawlFiles: forceRecrawlFiles})}/>
                <DecodeFailureResolutionConfigurator decodeFailureResolution={this.state.editedDecodeFailureResolution}
                                             handleDecodeFailureResolutionChange={(decodeFailureResolution: DecodeFailureResolution) => this.setState(
                                                 {editedDecodeFailureResolution: decodeFailureResolution})}/>
                <SectionHeader> <FormattedMessage id="studio.jobs.job-detail-page.schedule"
                                                  defaultMessage="Schedule"/></SectionHeader>
                <CombinedCronPicker value={this.state.editedCronString}
                                    validate={this.validateSchedule}
                                    mode={this.state.cronPickerMode}
                                    onModeSwitch={this.switchMode}
                                    onChange={(cronString) => {
                                      if (cronString === null) {
                                        cronString = undefined;
                                      }
                                      this.setState(Object.assign({}, this.state, {editedCronString: cronString}));
                                    }}/>
                <br/>
                {hasConfigurationChanged ? applyConfigurationStringChanges : null}
              </Tab>
            </Tabs>
          </div>
      );

      return (
          <MasterDetailLayout masterPane={masterPane} detailPane={detailPane} evenSplit/>
      );
    }

    return null;
  }

}

const mapStateToProps = (state, ownProps) => {
  const id = getParameterFromOwnProps(ownProps, "id"); //maybe injected by withRouter
  return {
    job: jobSelectors.getJobById(importJobSubmoduleName, state, id),
    isLoading: asyncDataSelectors.isLoading(jobSelectors.getJobDetailRequestState(importJobSubmoduleName, state)),
    isFinished: asyncDataSelectors.isFinished(jobSelectors.getJobDetailRequestState(importJobSubmoduleName, state)),
    isError: asyncDataSelectors.isError(jobSelectors.getJobDetailRequestState(importJobSubmoduleName, state)),
    error: asyncDataSelectors.getError(jobSelectors.getJobDetailRequestState(importJobSubmoduleName, state)),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    initialLoad: () => {
      const id = getParameterFromOwnProps(ownProps, "id"); //maybe injected by withRouter
      return dispatch(actions.loadJobById(id));
    },
    onUpdate: (job: ImportJob, updates: ImportJobUpdates) => {
      const updatedJob = Object.assign({}, job, updates);
      dispatch(actions.updateJobOnServer(updatedJob));
    },
    clearJobFilesFilter: () => {
      dispatch(actions.setJobFilesFilter({}));
    },
  };
};

export const JobDetailPage = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(WithApi(injectIntl(JobDetailPageComponent))));
